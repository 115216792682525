<!-- 用户充值页面 -->
<template>
  <div>
    <a-modal
      title="账户充值"
      width="50%"
      okText="确定"
      cancelText="取消"
      v-model:visible.sync="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-form ref="rechargeForm" :model="rechargeInfo">
          <a-row>
            <a-col>
              <a-form-item label="充值金额:" name="amount">
                <a-radio-group v-model:value="rechargeInfo.amount">
                  <a-radio :value="10">10元</a-radio>
                  <a-radio :value="20">20元</a-radio>
                  <a-radio :value="50">50元</a-radio>
                  <a-radio :value="100">100元</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <template #closeIcon
        ><SvgIcon iconName="sclose" className="svg201"
      /></template>
      <template #footer>
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleOk">立即充值</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script lang='js'>
import {
  ref,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  defineComponent,
  watch,
} from "vue";
import { message } from "ant-design-vue";
import { saveRechargeInfo } from "@/api/account/recharge";
import SvgIcon from "@/components/Svgicon";
export default defineComponent({
  name: "RechargeForm",
  components: {
    SvgIcon,
  },
  // 接受父组件传递的值
  props:{
    modalVisible:{
      type: Boolean,
      default: false,
    },
    accountId:{
      type:Number,
      default: 0,
    }    
  },  
  setup(props, context) {
    const rechargeForm = ref();
    const state = reactive({ 
      visible: false,
      confirmLoading: false,
      rechargeInfo:{
        id:null,
        accountId:null,
        amount: 10,
      },      
    });
    onBeforeMount(() => { });
    onMounted(() => {});
    const handleOk = () => {
      rechargeForm.value
        .validate()
        .then((success) => {
          if (success) {
            state.confirmLoading = true;
            saveRechargeInfo(state.rechargeInfo).then((resp) => {
              if (resp) {
                rechargeForm.value.resetFields();
                setTimeout(() => {
                      context.emit("saveCallBack",resp.code)
                      context.emit("handleModalVisible", false);
                      state.confirmLoading = false;
                    }, 1000);
              }
            });
          } else {
            message.error("充值申请失败！！！");
            return false;
          }
        })
        .catch((err) => {
          console.log("error", err);
        });      
    };
    const handleCancel = () => {
      context.emit("handleModalVisible", false);
    };
    const refData = toRefs(state);
    watch(
      () => [props.accountId,props.modalVisible],
      ([nv1,nv2]) => {
        state.visible = nv2;
        state.rechargeInfo.accountId = nv1;
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...refData,
      handleOk,
      handleCancel,
      rechargeForm,
    };
  },
});
</script>
<style lang='scss' scoped>
</style>