import { getRequest, postRequest, putRequest, deleteRequest } from '../request'

export const getRechargeInfoList = (res) => {
    return getRequest('/recharge/recharges', res)
}

export const getRechargeInfoListPage = (res) => {
    return postRequest('/recharge/recharges', res)
}

export const saveRechargeInfo = (res) => {
    if (res.id) {
        //更新App用户信息
        return putRequest('/recharge/recharge', res);
    }
    else {
        //添加App用户
        return postRequest('/recharge/recharge', res);
    }
}

//审核充值申请
export const checkRechargeInfo = (res) => {
    return postRequest('/recharge/recharge/chk', res)
}

export const deleteRechargeInfo = (res) => {
    return deleteRequest('/recharge/recharge/' + res.id)
}
